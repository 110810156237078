import React, {useEffect} from "react"
import { Link, graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faTwitch, faInstagram, faTiktok, faDiscord, faYoutube } from "@fortawesome/free-brands-svg-icons"
import Layout from "../components/layout"
import SEO from "../components/seo"
import moment from 'moment';

const callback = function(entries) {
  entries.forEach(entry => {

    // Is the element in the viewport ?
    if (entry.isIntersecting) {

      // Add the fadeIn class:
      entry.target.classList.add("motion-safe:animate-fadeIn");
    } else {

      // Otherwise remove the fadein class
      entry.target.classList.remove("motion-safe:animate-fadeIn");
    }
  });
};

const IndexPage = () => {

  const data = useStaticQuery(query);

  const social = data.strapiSocialMedia.data.attributes;

  const [noEvents, setNoEvents] = React.useState(false);

  const [shows, setShows] = React.useState([]);

  useEffect(() => {

    var found = false;

    if (data.allStrapiEvent.edges.length == 0) {
      setNoEvents(true);
    }

    data.allStrapiEvent.edges[0].node.data.forEach(show => {
      if (moment().unix() < (moment(show.attributes.EventDate).subtract(1, 'day').unix() )) {
        found = true;
        
      }
      
    });


    var shows = [];

    data.allStrapiEvent.edges[0].node.data.forEach(show => {

      if (moment().unix() < (moment(show.attributes.EventDate).subtract(1, 'day').unix() )) {
        shows.push(show);
      }
    });

    shows = shows.sort((a, b) => {
      if (moment(a.attributes.EventDate).unix() > moment(b.attributes.EventDate).unix()) {
        return 1;
      } else {
        return -1;
      }
    });

    setShows(shows);



    if (found == false) {
      setNoEvents(true);
    }



  }, []);

  useEffect(() => {
    const targets = document.querySelectorAll(".on-scroll");

      // Set up a new observer
      const observer = new IntersectionObserver(callback);

      // Loop through each of the target
      targets.forEach(function(target) {
        // Hide the element
        target.classList.add("opacity-0");

        // Add the element to the watcher
        observer.observe(target);
      });
  }, [])

  return <Layout>
    <SEO title="Home" />
    <div className="h-[95vh] md:min-h-[800px] bg-cover bg-left " style={{backgroundImage: "url('https://res.cloudinary.com/meshed-nyc/image/upload/v1741294925/josh-alba-bg_niqs2c.jpg')"}} >
      <div className="container mx-auto h-full flex flex-col sm:flex-row justify-end items-center sm:justify-center  py-10">
        <img src="https://res.cloudinary.com/meshed-nyc/w_600,c_fill,q_auto/Josh_Alba_1_wk902t.png" className="h-[60vh] sm:h-full max-h-full block"/>
        <div className="text-center md:mt-0 on-scroll">

          <h1 className="mb-5 font-hero  uppercase text-slate-800 text-white text-7xl  lg:text-8xl -rotate-2">Josh Alba</h1>
          <h3 className="font-lulo text-slate-800 sm:text-white font-medium sm:font-thin sm:text-xl text-lg sm:tracking-widest">STAND UP COMEDIAN</h3>
          <div className="mt-5 mb-10 flex items-center justify-between md:block md:space-x-10">
            <a href={social.Twitter} target="_blank" className="text-slate-800 text-xl"><FontAwesomeIcon icon={faTwitter} className="text-xl"/></a>
            <a href={social.Instagram} target="_blank" className="text-slate-800 text-xl"><FontAwesomeIcon icon={faInstagram}/></a>
            <a href={social.Twitch} target="_blank" className="text-slate-800 text-xl"><FontAwesomeIcon icon={faTwitch}/></a>
            <a href={social.TikTok} target="_blank" className="text-slate-800 text-xl"><FontAwesomeIcon icon={faTiktok}/></a>
            <a href={social.Discord} target="_blank" className="text-slate-800  text-xl"><FontAwesomeIcon icon={faDiscord}/></a>
            <a href="https://www.youtube.com/@mondaynitroparty" target="_blank" className="text-slate-800 text-xl"><FontAwesomeIcon icon={faYoutube}/></a>
          </div>
          
        </div>
      </div>
    </div>
    <div className="bg-slate-900">
      <form action="https://gmail.us14.list-manage.com/subscribe/post?u=40b2d9a64f7abbc6ea5cdbdcc&id=1b0ddb7548" method="POST">
      <div className="container mx-auto flex justify-center items-center py-10 text-center">
      <div className="md:w-full lg:w-1/2 xl:w-1/3  on-scroll">
        <div>
        <h5 className="text-sm sm:text-lg text-left font-lulo text-white uppercase mb-2 tracking-tight">Get updates on upcoming shows and projects</h5>
        </div>
        <hr className="w-12 border-2 my-6 on-scroll"/>
        <div className="flex w-full">
          <input type="email" name="EMAIL" className="rounded-l-sm grow bg-white p-3 font-sans col-span-3 sm:col-span-2" placeholder="you@email.com"/>
          <button className="px-8 rounded-r-sm col-span-2 text-center sm:col-span-1 bg-cyan-800 text-white font-bold">SUBSCRIBE</button>
        </div>
        </div>
      </div>
      </form>
    </div>
    <div className="bg-slate-900 pt-20">
      <div className="container mx-auto px-4">
        <div className="md:grid grid-cols-3 md:gap-8">
          <div className="mb-4 md:mb-0">
            <img src="https://res.cloudinary.com/meshed-nyc/image/upload/v1686069218/mnplogotransparantportrait_1_mbtx4n.png" className="w-full on-scroll" />
          </div>
          <div className="col-span-2">
            <h3 className="text-white text-xl tracking-tight font-lulo on-scroll">Check out <strong>Monday Nitro Party</strong> on YouTube</h3>
            <hr className="w-12 border-2 my-6 on-scroll"/>
            <p className="text-slate-200 text-lg mb-6 on-scroll">Every week, comedians Josh Alba & Jonas Barnes breakdown the week in wrestling. But this isn't your daddy's wrestling podcast. They're not just running down the shows, they're also bringing on guests from all over the industry to bring the funny as well. The turnbuckles are off and the steel is exposed.</p>
            <a className="text-center md:inline-block bg-rose-600 block w-full md:w-auto text-white italic py-3 px-8 font-bold uppercase on-scroll" href="https://www.youtube.com/@MondayNitroParty" target="_blank"><FontAwesomeIcon icon={faArrowRight}/> Watch Now</a>
          </div>
        </div>

      </div>

    </div>
    
    <div className="bg-slate-900 py-20 px-5" id="shows">
      <div className="container mx-auto max-w-6xl">
        <h1 className="text-white font-thin uppercase text-6xl -rotate-2 font-hero text-center mb-10">Upcoming Shows</h1>
        <hr className="border-white w-12 block mb-20 mx-auto border-t-8"/>
        {shows.length==0&&<div className="text-center py-10 px-5">
            <span className="text-gray-500">Nothing right now. Check back soon for upcoming shows!</span>
          </div>}
        {shows.map((event, i) => {
          if (moment().unix() > (moment(event.attributes.EventDate).add(1, 'day').unix() )) {
              
            return <div key={`event-${i}`} style={{height:0, display:'none'}}/>;
          }

          return <div className="grid grid-cols-3 lg:grid-cols-6 gap-3 mb-8  on-scroll" key={`event-${i}`}>
          <div className="lg:col-span-2">
            <h3 className="hidden md:block text-white uppercase text-lg font-lulo md:text-md lg:text-lg lg:font-bold tracking-tight">{moment(event.attributes.EventDate).format('MMMM Do')}</h3>
            <h3 className="block md:hidden text-white uppercase text-lg font-lulo md:text-md lg:text-lg lg:font-bold tracking-tight">{moment(event.attributes.EventDate).format('MMM D')}</h3>
            <span className="text-gray-500 font-lulo text-base lg:text-lg  tracking-tight">{moment(event.attributes.EventDate).format('h:mm a')}</span>
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-3">
            <h3 className="text-white font-lulo uppercase text-lg md:text-md lg:text-lg tracking-tight">{event.attributes.Title}</h3>
            <span className=" text-gray-500"><strong>{event.attributes.Venue}</strong><br/>{event.attributes.Address}</span>
          </div>
          <div className="col-span-3 md:col-span-1">
            <a href={event.attributes.URL} target="_blank" className=" font-bold font-sans uppercase text-sm md:text-md text-white border-white border text-white py-4 rounded-sm px-5 block text-center">Tickets</a>
          </div>
        </div>

        })}
        
      </div>
    </div>
    <div className="bg-black py-20 px-5">
      <div className="container mx-auto text-center  on-scroll">
        <h1 className="text-white uppercase font-lulo tracking-tight text-2xl mb-5">Contact me</h1>
          <p className="text-gray-500 mb-10">Book me for your upcoming comedy show, or reach out with any projects / opportunities.</p>
          <Link to="/contact" className="block md:w-auto md:inline-block w-full text-center bg-cyan-800 text-white py-4 uppercase font-bold px-5 mx-auto">Send me a message</Link>
      </div>
    </div>
  </Layout>
};

const query = graphql`
query MyQuery {
  strapiSocialMedia {
    data {
      attributes {
        Facebook
        Instagram
        Twitch
        Twitter
        TikTok
        Discord
      }
    }
  }
  allStrapiEvent (sort: {fields: data___attributes___EventDate, order: ASC}) {
    edges {
      node {
        data {
          attributes {
            EventDate
            Title
            Address
            Venue
            URL
          }
        }
      }
    }
  }
}
`;

export default IndexPage
